import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getDatabase } from 'firebase/database'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
import { getAnalytics, isSupported as isAnalyticsSupported } from 'firebase/analytics'

export default defineNuxtPlugin(async nuxtApp => {
  const config = useRuntimeConfig()
  const env = config.public
  const app = initializeApp({
    apiKey: env.FIREBASE_API_KEY,
    authDomain: env.FIREBASE_AUTH_DOMAIN,
    projectId: env.FIREBASE_PROJECT_ID,
    storageBucket: env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
    appId: env.FIREBASE_APP_ID,
    measurementId: env.FIREBASE_MEASUREMENT_ID,
    databaseURL: env.FIREBASE_DATABASE_URL
  })
  const auth = getAuth(app)
  const firestore = getFirestore(app)
  const realtimedb = getDatabase(app)
  const storage = getStorage(app)
  const functions = getFunctions(app, 'europe-west3')

  const analyticsSupported = await isAnalyticsSupported()

  if (
    analyticsSupported &&
    env.FIREBASE_MEASUREMENT_ID
  ) {
    getAnalytics(app)
  }

  nuxtApp.provide('firebase', {
    app,
    auth,
    firestore,
    realtimedb,
    storage,
    functions
  })
})
