import { default as _91streamerId_93GPRazbkMrRMeta } from "/Users/ppl/Documents/_/play-4-live/app/pages/[streamerId].vue?macro=true";
import { default as indexQG9at8TD1ZMeta } from "/Users/ppl/Documents/_/play-4-live/app/pages/index.vue?macro=true";
import { default as welcomeeBlVe442eBMeta } from "/Users/ppl/Documents/_/play-4-live/app/pages/welcome.vue?macro=true";
export default [
  {
    name: _91streamerId_93GPRazbkMrRMeta?.name ?? "streamerId",
    path: _91streamerId_93GPRazbkMrRMeta?.path ?? "/:streamerId()",
    meta: _91streamerId_93GPRazbkMrRMeta || {},
    alias: _91streamerId_93GPRazbkMrRMeta?.alias || [],
    redirect: _91streamerId_93GPRazbkMrRMeta?.redirect,
    component: () => import("/Users/ppl/Documents/_/play-4-live/app/pages/[streamerId].vue").then(m => m.default || m)
  },
  {
    name: indexQG9at8TD1ZMeta?.name ?? "index",
    path: indexQG9at8TD1ZMeta?.path ?? "/",
    meta: indexQG9at8TD1ZMeta || {},
    alias: indexQG9at8TD1ZMeta?.alias || [],
    redirect: indexQG9at8TD1ZMeta?.redirect,
    component: () => import("/Users/ppl/Documents/_/play-4-live/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: welcomeeBlVe442eBMeta?.name ?? "welcome",
    path: welcomeeBlVe442eBMeta?.path ?? "/welcome",
    meta: welcomeeBlVe442eBMeta || {},
    alias: welcomeeBlVe442eBMeta?.alias || [],
    redirect: welcomeeBlVe442eBMeta?.redirect,
    component: () => import("/Users/ppl/Documents/_/play-4-live/app/pages/welcome.vue").then(m => m.default || m)
  }
]