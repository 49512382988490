import revive_payload_client_DnB27FNYAv from "/Users/ppl/Documents/_/play-4-live/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.30_@unocss+reset@0.58.7_encoding@0.1.13_f_uwtquagtua2snbgdwmub6macwu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uC7RxJMwiD from "/Users/ppl/Documents/_/play-4-live/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.30_@unocss+reset@0.58.7_encoding@0.1.13_f_uwtquagtua2snbgdwmub6macwu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_rBBHff8pIa from "/Users/ppl/Documents/_/play-4-live/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.30_@unocss+reset@0.58.7_encoding@0.1.13_f_uwtquagtua2snbgdwmub6macwu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_E7j4myEtZn from "/Users/ppl/Documents/_/play-4-live/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.30_@unocss+reset@0.58.7_encoding@0.1.13_f_uwtquagtua2snbgdwmub6macwu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_hS5VPiF4BQ from "/Users/ppl/Documents/_/play-4-live/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.30_@unocss+reset@0.58.7_encoding@0.1.13_f_uwtquagtua2snbgdwmub6macwu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/Users/ppl/Documents/_/play-4-live/app/.nuxt/components.plugin.mjs";
import prefetch_client_TrPml4iU2i from "/Users/ppl/Documents/_/play-4-live/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.30_@unocss+reset@0.58.7_encoding@0.1.13_f_uwtquagtua2snbgdwmub6macwu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_mV7fB2r1fm from "/Users/ppl/Documents/_/play-4-live/node_modules/.pnpm/nuxt-quasar-ui@2.1.2_@quasar+extras@1.16.9_quasar@2.16.4_rollup@4.17.2/node_modules/nuxt-quasar-ui/dist/runtime/plugin.mjs";
import chunk_reload_client_29bdRGYmWN from "/Users/ppl/Documents/_/play-4-live/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.11.30_@unocss+reset@0.58.7_encoding@0.1.13_f_uwtquagtua2snbgdwmub6macwu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import firebase_Wjz9XrZo01 from "/Users/ppl/Documents/_/play-4-live/app/plugins/firebase.ts";
export default [
  revive_payload_client_DnB27FNYAv,
  unhead_uC7RxJMwiD,
  router_rBBHff8pIa,
  payload_client_E7j4myEtZn,
  check_outdated_build_client_hS5VPiF4BQ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_TrPml4iU2i,
  plugin_mV7fB2r1fm,
  chunk_reload_client_29bdRGYmWN,
  firebase_Wjz9XrZo01
]