<template>
  <nuxt-layout>
    <nuxt-page />
  </nuxt-layout>
</template>

<script setup lang="ts">
import { ref as dbRef, onValue } from 'firebase/database'

import type { UserData } from 'shared/types/user'

const nuxtApp = useNuxtApp()
const database = nuxtApp.$firebase.realtimedb
const usersRef = dbRef(database, 'twitch/users')

const usersState = useState<UserData[]>('users', () => [])

function sortUsers (users: UserData[]) {
  users.sort((u1, u2) => {
    const name1 = u1.name.toUpperCase()
    const name2 = u2.name.toUpperCase()

    if (name1 < name2) {
      return -1
    }

    if (name1 > name2) {
      return 1
    }

    return 0
  })
}

function fetchUsers () {
  return onValue(usersRef, snapshot => {
    const users: UserData[] = []

    snapshot.forEach(childSnapshot => {
      const userData: UserData = childSnapshot.val()

      if (!Array.isArray(userData.lastClips)) {
        userData.lastClips = []
      }

      if (!Array.isArray(userData.socials)) {
        userData.socials = []
      }

      users.push(userData)
    })

    sortUsers(users)

    usersState.value = users
  })
}

onMounted(() => {
  fetchUsers()
})
</script>

<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
